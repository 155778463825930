<template>
  <div class="container" element-loading-text="Loading" v-loading="listLoading">
    <BodyContent :pageData="pageData" @initData="initData">
      <div slot="table">
        <div class="table-body-content" style="padding: 20px 20px 10px 20px">
          <div class="table-header">
            <div class="search-box">
              <div class="search-title">考核组状态</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search-box">
              <div class="search-title">关键字</div>
              <el-input
                placeholder="请输入考核组名称、描述进行查询"
                clearable
                style="width: 250px; margin-right: 20px"
                v-model.trim="requestParams.keyword"
                maxlength="100"
              />
            </div>
            <el-button type="text">重置</el-button>
          </div>


          <div class="table-content">
            <el-button type="text" icon="el-icon-plus" @click="dialogFormVisible = true">新增</el-button>
            <el-button type="text" icon="el-icon-delete">删除</el-button>
            <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
              <el-table-column align="center" type="selection" width="80"></el-table-column>
              <el-table-column align="center" label="考核组名称">
                <template slot-scope="scope">{{scope.row.groupName}}</template>
              </el-table-column>
              <el-table-column align="center" label="考核组状态">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.state"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column align="center" label="考核部门数">
                <template slot-scope="scope">{{scope.row.num}}</template>
              </el-table-column>
              <el-table-column align="center" label="考核组描述">
                <template slot-scope="scope">{{scope.row.describe}}</template>
              </el-table-column>
              <el-table-column align="center" label="创建人">
                <template slot-scope="scope">{{scope.row.founder}}</template>
              </el-table-column>
              <el-table-column align="center" label="创建时间">
                <template slot-scope="scope">{{scope.row.createTime}}</template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit-outline" @click="dialogFormVisible = true"></el-button>
                  <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>


        <el-dialog :visible.sync="dialogFormVisible" width="70%">
          <div slot="title" class="dialog_title">
            {{ruleForm.id ? '编辑' : '新增'}}
            <el-button icon="el-icon-rank" type="text" ></el-button>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <el-form-item label="考核组名称：" prop="name" style="width:50%;display:inline-block">
              <el-input v-model="ruleForm.name" maxlength="200"></el-input>
            </el-form-item>
            <el-form-item label="考核组状态：" prop="state" style="width:50%;display:inline-block">
              <el-switch
                    v-model="ruleForm.state"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
            </el-form-item>
            <el-form-item label="考核组描述：" >
              <el-input type="textarea" show-word-limit :rows="2" v-model="ruleForm.describe" maxlength="1000"></el-input>
            </el-form-item>
            <el-form-item label="考核对象：" prop="obj">
              <div class="table-content">
                <el-button type="text" icon="el-icon-plus" @click="innerVisible = true">新增</el-button>
                <el-button type="text" icon="el-icon-delete">删除</el-button>
                <el-table :data="ruleForm.obj" stripe border ref="bannerTable" row-key="id" fit>
                  <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
                  <el-table-column align="center" label="部门名称">
                    <template slot-scope="scope">{{scope.row.name}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="所属地区">
                    <template slot-scope="scope">{{scope.row.address}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="部门全称">
                    <template slot-scope="scope">{{scope.row.allName}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                      <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
            
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
          </div>
          <el-dialog
              width="50%"
              :visible.sync="innerVisible"
              append-to-body>
              <div slot="title" class="dialog_title">
            选择部门
            <el-button icon="el-icon-rank" type="text" ></el-button>
          </div>
              <el-row :gutter="20">
                <el-col :span="12" >
                  <div class="title">待选部门</div>
                  <div class="left">
                    <el-input
                      placeholder="请输入部门名称进行查询"
                      clearable
                      style="width: 100%"
                      v-model.trim="requestParams.keyword"
                    />
                    <div class="list">
                      <div v-for="(item) in 7" :key="item">
                        行政部（四川省）
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="title">已选部门 <span style="float:right">已选总数：{{ruleForm.obj.length}}</span> </div>
                  <el-table :data="ruleForm.obj" stripe border ref="bannerTable" row-key="id" fit>
                    <el-table-column align="center" label="操作">
                      <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="所属地区">
                      <template slot-scope="scope">{{scope.row.address}}</template>
                    </el-table-column>
                    <el-table-column align="center" label="部门名称">
                      <template slot-scope="scope">{{scope.row.name}}</template>
                    </el-table-column>
                    
                  </el-table>
                </el-col>
              </el-row>
            </el-dialog>
        </el-dialog>
      </div>
    </BodyContent>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      innerVisible:false,
      dialogFormVisible:false,
      listLoading: false,
      stateList: [],
      requestParams: {
        state: "",
      },
      rules:{
        name:[{ required: true, message: '请填写名称' }],
        state:[{ required: true, message: '请选择状态' }],
        obj:[{ type: 'array',required: true, message: '请选择考核对象',trigger: 'change'  }],
      },
      ruleForm:{
        name:'',
        id:'',
        state:false,
        obj:[]
      },
      options: [],
      pageData: {},
      tableData:[
        {groupName: '考核一组', state: false, num: 3, describe: '考核组描述', founder: '张婷', createTime: '2022-02-25'},
        {groupName: '考核二组', state: true, num: 3, describe: '考核组描述', founder: '刘佳', createTime: '2022-02-25'}
      ]
    };
  },
  methods: {
    initData() {},
  },
};
</script>
<style lang="scss" scoped>
.title{
    margin-bottom: 5px;
}
.left{

  border: 1px solid #DCDFE6;
  border-radius: 4px;
  /deep/.el-input__inner{
    border: 0;
    border-bottom: 1px solid #DCDFE6;
  }
  .list{
    padding: 0 5px;
  div{
    padding: 10px;
  }
}
}

</style>
